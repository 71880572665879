.mobile-page {

    .form-control {
        font-size:1.2em !important;
    }
    .MuiInputBase-root {
        font-size:1.2em !important;
    }
    label {
        font-size:'1.2em !important';
    }
    input select {
        font-size:'1.2em !important';
    }

}