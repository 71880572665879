.main-header {
    color:#333;
    /*background-color: #ededed;*/
    /*background-color: #fff;*/
    background:#F8F8F8 !important;
    padding-top: 1em;
    position:relative;
    top: 0;
    width:100%;
    max-height: 17vh;
    min-height: 17vh;
}
.logo-form {
    max-width:60%;
    max-height: 12vh;
}
.text-logo-form{
    text-align: left !important;
}
@media (max-width: 65em){
    .text-header{
        line-height: 1em;
        text-align: right;
    }
}

@media (min-width: 65em){
    .text-header{
        padding-top: 1em;
        line-height: 1em;
        text-align: right;
    }
}
.text-nome-profilo{
    padding-top:1em;
    padding-left: 1em;
}
.logo-logout {
    height:2em;
}
.hr-line{
    background-color:#011e87;
    height:3px;
}
.hr-line-cert{
    background-color:#296E9C;
    height:3px;
}