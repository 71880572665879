.custom-form
{
    width:100% !Important;
    max-width:1700px !Important;
    padding-top:5em !Important;
    margin:auto !Important;
    height: 100% !Important;
}
.custom-form-popup
{
    width:100% !Important;
    max-width:1700px !Important;
    margin:auto !Important;
    height: 100% !Important;
}
.bg-form {
    flex-grow: 1;
    background:#F8F8F8 !important;
  }
  .sp-center {
    position: absolute;
    top: 30vh;
    left: 45%;
    z-index: 1030;
    
}​
  .makeStyles-paper-12{
    text-align:left !Important;
    color:rgba(0, 0, 0, 0.87) !Important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding:0px !important;
    padding-right:30px !important;
    background-color: #fff !important;
  }
  .MuiAutocomplete-inputsm input[class*="MuiInputBase-input"] {
    padding:6px 4px !important;
  }
  .MuiInputBase-root.Mui-disabled{
    color:#495057!Important;
    background-color: #e9ecef!Important;
  }
  .MuiAppBar-colorPrimary:has(.bg-head-form-cert) {
    background-color: #2370A4!Important;
  }
  .MuiAppBar-colorPrimary:has(.bg-head-form) {
    background-color: #011e87!Important;
  }
  .Mui-focused {
    border-color: #80bdff!important;
    outline:0!important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
  }

  .Mui-focused fieldset {
    border-color: #80bdff!important;
    border:1pt!important;
  }
  
  .search{
    padding-left: 30px !important;
  }
  .MuiAutocomplete-inputsm-search input[class*="MuiInputBase-input"] {
    padding:6px 4px !important;
    padding-left: 30px !important;
  }
  button:focus {
    outline: 0px !important;
  }
  .signCanvas{
    border:1px solid;
    width:100% !important;
    height:90% !important;
  }
  .input-icon {
    float: left;
    margin-left: 6px;
    margin-top: -27px;
    position: relative;
    z-index: 0;
}
.form-popup{
  max-width: 800px !important;
  min-width: 600px !important;
}
.form-popup-big{
  max-width: 800px !important;
  min-width: 600px !important;
  max-height: 800px !important;
  min-height: 400px !important;
}

.small-label{
  margin-bottom: 0 !important;  
}
.text-danger-min{
  color:red;
  font: weight 600;
  font-size: 10px !important;
}