#wrapper {
    overflow-x: hidden;
}

.sidebar-wrapper {
    /*min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;*/
    flex:1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 4em;
    height: 100%;
    transition: width .4s ease;

}
@media (max-width: 65em){
    #sidebar-wrapper  {
        padding: 0rem 0rem;
        font-size: 1.1rem;
        z-index: 1!important;
        flex:1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        /*overflow-y:scroll;*/
        left: 0;
        width: 15em;
        height: 100%;
        transition: width .4s ease;
    }
    
    #page-content-wrapper {
        min-width: 0;
        width: 100%;
        height:100%;
        
    }   
}

#wrapper.toggled #sidebar-wrapper {
    width: 2.5em;
}
#wrapper.toggled #sidebar-content-wrapper {
    padding-left: 1.5em;
}
#sidebar-content  {
    padding: 0.875rem 1.25rem;
    font-size: 1.1rem;
}
.sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
    font-weight: bold;
}
#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
    height: 100%;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.text-weight{
    font-weight: bold;
}
#sidebar-header{
    width:100%;
    text-align: right;
    font-size: 1.7rem;
    margin-top: -0.3em;
}
.list-group-item {
    padding: 0.5rem 1.0rem !important;
}
#sidebar-header:hover {
    font-weight: bold;
    /*font-size: 2rem;*/
    cursor:pointer;
  }
  .bullet-list{
    font-size:22px;
    color:#011e87;
  }

@media (max-width: 65em) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    
   
}


