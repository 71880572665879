.grid-head {
    background-color:#fff !important;
    border-bottom: 1px solid #011e87 !important;
    font-weight: bold !important;
}
.rt-tr-group{
    background: #FDFDFD;
}
.rt-tr-group:nth-of-type(even){
    background: #fff;
}
.filter-control {
    font-size: 1.2rem;
    padding: 15px 5px;
  }
.row-selected td[class*= "MuiTableCell-body"]{
    /*background-color:#007fff !important;*/
    background-color:#3f51b5 !important;
    color:  #fff !important;
}
.row-selected-cert td[class*= "MuiTableCell-body"]{
    /*background-color:#007fff !important;*/
    background-color:#074873 !important;
    color:  #fff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
color:#3f51b5 !important;
}