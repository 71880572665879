body, html {
    height: 100% !important;
    background:#F8F8F8 !important;
  }
.text-center {
    text-align: center;
}
.btn-mbg
{
    background-color: rgba(18, 35, 95, 0.84) !important;
    /*background-color: rgba(41, 110, 156, 1) !important;*/
    /*background-color: rgba(12, 77, 161, 1) !important;*/
    /*296E9C colore nuovo sito*/
    color:#fff !important;
}

.btn-mbg:hover{
    background-color:#01114a !important;
}

.btn-mbg-cert
{
    background-color: rgba(41, 110, 156, 1) !important;
    /*background-color: rgba(41, 110, 156, 1) !important;*/
    /*background-color: rgba(12, 77, 161, 1) !important;*/
    /*296E9C colore nuovo sito*/
    color:#fff !important;
}

.btn-mbg-cert:hover{
    background-color:#254F6B !important;
}

.btn-mbg1
{
    background-color: rgba(63, 6, 46, 0.84) !important;
    color:#fff !important;
}

.btn-mbg1:hover{
    background-color:#3d062d !important;
}

.btn-mbg-gray
{
    background-color: rgba(37, 37, 37, 0.84) !important;
    color:#fff !important;
}

.btn-mbg-gray:hover{
    background-color:#080808 !important;
}

.btn-small
{
    font-size: 7pt !important;
}
.page-container{
    display:flex;
    flex-direction: column;
    width:100%;
}
.content-wrap{
    flex:1;
    flex-wrap: nowrap !important;
    height: 100%;
    overflow: auto;
    min-height: 83vh;
    position:relative;
}
.content-center {
    position: absolute;
    width: 100%;
    top: 20%;
    text-align: center;
}​

#content-form{
    z-index: 0 !important;
    width: 100%;
    /*position:relative;*/
}
.welcome-page-container{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100vh;
}
.welcome-card:hover{
    box-shadow: 10px 10px 20px -5px #192824;
    transition: opacity 0.3s ease-in-out;
}
.bg-head-form{
    background-color: #011e87 !important;
}
.bg-head-form-cert{
    background-color: #2370A4 !important;
}
.color-serv{
    color: #011e87 !important;
}
.color-cert{
    color: #2370A4 !important;
}
@media (max-width: 65em){
    #content-form{
        padding-left: 4em;
    }
}