.login-form
{
    width:100%;
    max-width: 330px;
    padding:15px;
    margin:auto;
    height: 100%;
}
.login-page-container{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100vh;
}
.content-wrap{
    flex:1;

}